import { SELECTED_PAYMENT_LOCATION_ID_LOCAL_STORAGE } from '@/constants/payments';
import { Routes } from '@/utils/routes';
import { useCustomers } from 'framework';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';

/**
 * Gets a persisted **PAYMENT ENABLED locationId** from the last visited payments location
 *
 * @return string|null
 */
export default function usePaymentLocationId(): string | null {
  const { query, pathname } = useRouter();
  const { customer } = useCustomers();
  const [paymentLocationId, setPaymentLocationId, removePaymentLocationId] = useLocalStorage(SELECTED_PAYMENT_LOCATION_ID_LOCAL_STORAGE, null);
  const queryLocationId = Array.isArray(query.locationId) ? query.locationId[0] : query.locationId;

  useEffect(() => {
    if (pathname === Routes.paymentsLocation && queryLocationId && queryLocationId !== paymentLocationId) {
      setPaymentLocationId(queryLocationId);
    }
  }, [pathname, queryLocationId, paymentLocationId, setPaymentLocationId]);

  useEffect(() => {
    // Clear the pre-selected locationId if the customer has been changed and it has no access to that particular location
    if (customer?.locations?.length > 0 && paymentLocationId && !customer.locations.some((loc) => loc.locationId === paymentLocationId)) {
      removePaymentLocationId();
    }
  }, [customer, paymentLocationId, removePaymentLocationId]);

  return useMemo(() => queryLocationId ?? paymentLocationId, [queryLocationId, paymentLocationId]);
}
