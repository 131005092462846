import "../../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.15_@types+node@18.15.11_less@4.2.2_sass@1.83.4_stylus@0.6_5uk7xvk3bdlm6xt7m54pkcm3va/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fhome%2FtotalTakings%2FtotalTakings.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA6VVUW%2BbMBB%2Bz6%2BwNE1qpVIBIZTQl6Xb%2Bge698qBC%2FECNrKPhmzKf59sIKmDWbvuBcTl%2FN33fXe%2B3KJAWv6gO8YL9ZwJjpRxkE94KOH5Odgt4xhefPJ7RshatJ5ivxgvUtJwBXg%2FO85urfNrquCrGyMwGFtgxRZTEkd1e28wZQ7SkzRnjUpJoqOXoCXjOxsrNFgILXo5ZEJSZIKnhPEtSIYaNxOlkFYkZ6ou6SElmxJMaf32ciYh605Lsdfhn41Ctjl42grgmBJV0wy8NeAegOuMmua5MSEI65YEvZJBWUXb4awO68%2BRaJrtCikanns9z0%2Br6DH6vniX9nQrXkB2HRkDxfGj%2Fy0yFjRS6VAtGEeQY2yQUsgnpAh2hbnBftOvTJRNxd16ZLGmV2EU3JDuMY9vSHCtc2nJCu4xhEqlJIOO2dm9JOks6j32UNQpCWLnWAiqUxwCoglzplhdIqM8rArK%2BEODKLiNvTDYG8FR3wUYyBFSMg6nTod9p%2Fcsx%2B1oJszgGiPOFow5UK6osVo9iPxgs4gNC6vkwOM1tdDlG8sEn7ijdwa2Jx0m9mAP3xWVBeNeCRucKoEMy4ueJCPfIpdvvhPu7MWqEg1HG3p5Cd2hTLTE5O374ML3dbkvFeSMkquKca9XH%2Ft%2B3V4b5H9cceNhPo5AplZaty8GCsln9%2BlJe50GH90Cl%2FH%2FC7zzPybwNLNB75Clu%2F9v6aJm1swWCIdUazcMbT2H1wJRVK5f%2BpGNL%2BOyZzOp5u%2Bb8uzHKz0Wybk%2FUXKU%2Fhb5yYl6z75w7q2Ja%2FLRqQtPJh7%2FAJqLAPtbCAAA%22%7D"
export var baseContainerStyle = 'totalTakings_baseContainerStyle__1k966ev1';
export var containerStyle = 'totalTakings_containerStyle__1k966ev0';
export var errorStateStyle = 'totalTakings_errorStateStyle__1k966ev3 totalTakings_baseContainerStyle__1k966ev1';
export var iconContainerStyle = 'totalTakings_iconContainerStyle__1k966ev7';
export var linkStyle = 'totalTakings_linkStyle__1k966ev2 totalTakings_baseContainerStyle__1k966ev1';
export var loadingStateStyle = 'totalTakings_loadingStateStyle__1k966ev4 totalTakings_baseContainerStyle__1k966ev1';
export var titleStyle = 'totalTakings_titleStyle__1k966ev8';
export var transactionAmountStyle = 'totalTakings_transactionAmountStyle__1k966ev9';
export var transactionsBodyStyle = 'totalTakings_transactionsBodyStyle__1k966ev6 _1yasxssa _86u5x8d _86u5x82 _86u5x8p _86u5x88';
export var tryAgainButtonStyle = 'totalTakings_tryAgainButtonStyle__1k966ev5';