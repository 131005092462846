import { AnalyticsHomeVariantConstants } from '@/constants/analytics/home-variant';
import { TAKE_PAYMENT_QUERY_KEY, TakePaymentQueryValues } from '@/constants/payments';
import usePaymentLocationId from '@/hooks/payments/usePaymentLocationId';
import { Permissions } from '@/utils/permissions';
import { Routes } from '@/utils/routes';
import { AccountBalance, PaymentLink, VirtualTerminal } from '@dojo-engineering/react-icons';
import { useCustomers } from 'framework';
import TiledSection from './tiledSection';

type TakePaymentSectionProps = {
  shouldShowPaymentLinks: boolean;
  shouldShowVirtualTerminal: boolean;
  showPayByBank: boolean;
  userSelectedLocationId: string | null;
};

export default function TakePaymentSection({
  shouldShowPaymentLinks,
  shouldShowVirtualTerminal,
  showPayByBank,
  userSelectedLocationId,
}: TakePaymentSectionProps) {
  const { customer } = useCustomers();
  const persistedPaymentLocationId = usePaymentLocationId();
  const paymentLocationId = userSelectedLocationId ?? persistedPaymentLocationId;
  const { locations } = customer ?? {};

  const preSelectedLocationIdEnabled = (key: 'paymentLinks' | 'virtualTerminal' | 'payByBank') => {
    const location = locations.find((loc) => loc.locationId === paymentLocationId);
    if (location?.remotePaymentAppConfig?.[key]?.enabled) {
      return location.locationId;
    }
  };

  const paymentLinkEnabledLocation =
    preSelectedLocationIdEnabled('paymentLinks') || locations.find((loc) => loc?.remotePaymentAppConfig?.paymentLinks?.enabled)?.locationId;
  const virtualTerminalEnabledLocation =
    preSelectedLocationIdEnabled('virtualTerminal') || locations.find((loc) => loc?.remotePaymentAppConfig?.virtualTerminal?.enabled)?.locationId;
  const payByBankEnabledLocation =
    preSelectedLocationIdEnabled('payByBank') || locations.find((loc) => loc?.remotePaymentAppConfig?.payByBank?.enabled)?.locationId;

  const tiles = [
    {
      heading: 'home:home.list.payment_links',
      body: 'home:home.list.payment_links_description',
      Icon: <PaymentLink />,
      linkPath: `${Routes.payments}${paymentLinkEnabledLocation ? `/${paymentLinkEnabledLocation}` : ''}`,
      permissions: [Permissions.paymentLinksCreate],
      shouldShow: shouldShowPaymentLinks,
      analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.paymentLink,
      analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.paymentLinks,
      query: { [TAKE_PAYMENT_QUERY_KEY]: TakePaymentQueryValues.PaymentLink },
    },
    {
      heading: 'home:home.list.virtual_terminal',
      body: 'home:home.list.virtual_terminal_description',
      Icon: <VirtualTerminal />,
      linkPath: `${Routes.payments}${virtualTerminalEnabledLocation ? `/${virtualTerminalEnabledLocation}` : ''}`,
      permissions: [Permissions.paymentLinksView],
      shouldShow: shouldShowVirtualTerminal,
      analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.virtualTerminal,
      analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.virtualTerminal,
      query: { [TAKE_PAYMENT_QUERY_KEY]: TakePaymentQueryValues.VirtualTerminal },
    },
    {
      heading: 'home:home.list.pay_by_bank',
      body: 'home:home.list.pay_by_bank_description',
      Icon: <AccountBalance />,
      linkPath: `${Routes.payments}${payByBankEnabledLocation ? `/${payByBankEnabledLocation}` : ''}`,
      shouldShow: showPayByBank,
      analyticsPageSection: AnalyticsHomeVariantConstants.pageSection.payByBank,
      analyticsActionSuffix: AnalyticsHomeVariantConstants.actionSuffixes.payByBank,
      query: { [TAKE_PAYMENT_QUERY_KEY]: TakePaymentQueryValues.PayByBank },
    },
  ];

  return <TiledSection heading="home:home.list.take_a_payment" tiles={tiles} />;
}
